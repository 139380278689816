import styled from "styled-components";

const BlogSectionWrapper = styled.section`
  padding-top: 160px;
  padding-bottom: 160px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  @media (max-width: 990px) {
    padding-top: 180px;
    padding-bottom: 60px;
    min-height: auto;
  }
  @media (max-width: 767px) {
    padding-top: 130px;
    padding-bottom: 40px;
    min-height: auto;
  }

  @media only screen and (max-width: 480px) {
    min-height: 100vh;
    background: none;
    padding-top: 100px;
  }

  span.label-text {
    display: flex;
    flex-align: center;
  }

  .particle {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 767px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .row {
    position: relative;
    z-index: 1;
  }

  .post-feed {
    display: grid;
    justify-content: space-between;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}

.blog-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0 60px 0;
}

@media (max-width: 980px) {
    .post-feed {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 680px) {
    .post-feed {
        grid-template-columns: 1fr;
    }
}

.post-card {
    color: inherit;
    text-decoration: none;
    margin-bottom: 10px;
}

.post-card:hover {
    text-decoration: none;
}

.post-card-tags {
    margin: 0 0 5px 0;
    font-size: 1.2rem;
    line-height: 1.15em;
    color: gray;
}


.post-card-title {
    margin: 0 0 10px 0;
    padding: 0;
}

.post-card-excerpt {
    font-size: 1.2rem;
    line-height: 1.55em;
    color: #3c484e;
}

.post-card-image {
    margin: 0 0 10px 0;
    width: auto;
    height: 200px;
    background: #5B7A81 no-repeat center center;
    background-size: cover;
}

.post-card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0 0;
    color: gray;
}

.post-card-footer-left {
    display: flex;
    align-items: center;
}

.post-card-footer-right {
    display: flex;
    flex-direction: column;
}

.post-card-avatar {
    width: 40px;
    height: 40px;
    margin: 0 7px 0 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-card-avatar .author-profile-image {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.post-card-avatar .default-avatar {
    width: 26px;
}

  .button__wrapper {
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    .reusecore__button {
      &.outlined {
        border-color: rgba(82, 104, 219, 0.2);
      }
    }
  }
`;

export { BlogSectionWrapper };
